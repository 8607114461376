/** @jsxImportSource @emotion/react */

import logo from './logo.svg';
import './App.css';
import { User } from "lucide-react"
import { Phone, MapPin, Mail, Scissors, Home, Image, BadgeDollarSign, Instagram, Facebook, GraduationCap} from "lucide-react"
import { useState, useRef, useEffect } from 'react';
import { ScrollTop } from 'primereact/scrolltop';
import { css } from '@emotion/react'
import { motion } from 'framer-motion'
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/table"
import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/card";
import 'non.geist'


function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(require.context('../public/gallery', false, /\.(png|jpe?g|svg)$/));


function App() {

  const services = [
    { name: "Dámský střih – začištění konečků", price: "100,-" },
    { name: "Dámský střih – sestříhávání, sestřih do tvaru atd.", price: "150,-" },
    { name: "Dámský střih – mix technik, složitější stříhání", price: "200,-" },
    { name: "Pánský střih", price: "200,-" },
    { name: "Mytí a regenerace *", price: "90,-/140,-/190,-" },
    { name: "Maska, regenerační zábal *", price: "50,-/ 100,-/ 150,-" },
    { name: "Tónování, přeliv *", price: "280,-/470,-/740,-" },
    { name: "Odbarvení *", price: "320,-/480,-/700,-" },
    { name: "Techniky do fólie – melír, baleyage atd. *", price: "520,-/730,-/940,-" },
    { name: "Barva permanentní *", price: "300,-/500,-/800,-" },
    { name: "Foukaná přes kartáč, žehlení, kulmování atd.", price: "120,-/170,-/220,-" },
    { name: "Finální úprava – styling: olej, tužidlo, gel, lak atd. *", price: "20,-" },
  ]

  const [showAll, setShowAll] = useState(false);
  const [fade, setFade] = useState(true); // Stav pro kontrolu opacity

  // Pokud je showAll true, zobrazí se všechny obrázky, jinak jen 6
  const visibleImages = showAll ? images : images.slice(0, 6);

  const galleryRef = useRef(null); // 

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
    // Pokud (zobrazit méně), posune se pohled na začátek galerie
    if (showAll && galleryRef.current) {
      galleryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  const [isMapExpanded, setIsMapExpanded] = useState(false)

  const contactInfo = [
    { icon: Phone, title: 'Telefon', content: '+420 606 233 992' },
    { icon: MapPin, title: 'Adresa', content: 'Bráfova tř. 525/41, Třebíč 674 01' },
    { icon: Mail, title: 'E-mail', content: 'alice.homolkova@post.cz' },
    { icon: Scissors, title: 'Objednání předem', content: ['Pracuji hlavně v odpoledních hodinách.'] }
  ]

  // Hlášky, které se mají střídat
  const slogans = [
    ",,Vlasy - moje láska\"",
    ",,Práce jako hobby\"",
    ",,Citlivě k přání zákazníka\"",
    ",,Kreativita na počkání\""
  ];

  // Stav pro aktuální index hlášky
  const [currentSloganIndex, setCurrentSloganIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Fade out
      setFade(false);

      // Po 1 sekundě změň hlášku a fade in
      setTimeout(() => {
        setCurrentSloganIndex((prevIndex) => (prevIndex + 1) % slogans.length);
        setFade(true);
      }, 1000);
    }, 5000); // Změna hlášky každých 5 sekund

    return () => clearInterval(intervalId);
  }, [slogans.length]);

  return (
    <div className="App">
      <div className="min-h-screen bg-gradient-to-b from-pink-400 to-pink-200">
      <nav className="bg-pink-500 shadow-md">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <img
                className="h-10 w-10 rounded-full object-cover"
                src="logo.jpg"
                alt="Logo"
                width={40}
                height={40}
              />
              <span className="ml-2 text-xl font-semibold text-white">Alice Homolková</span>
            </div>
            <div className="hidden md:flex items-center space-x-4">
    {['Domů', 'Galerie', 'Ceník', 'Kontakt'].map((item) => {
    // Funkce pro odstranění diakritiky
    const removeDiacritics = (str) =>
      str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    
      return (
        <a
          key={item}
          href={`#${removeDiacritics(item.toLowerCase())}`}
          className="text-white hover:text-pink-200 transition duration-150 ease-in-out"
        >
          {item}
        </a>
      );
    })}
</div>
          </div>
        </div>
      </nav>

      <main id="main-first" className="flex-grow flex items-center justify-center px-4 py-12">
        <div id="coll-w" className="max-w-3xl w-full overflow-hidden m-10">
          <div id="to-col" className="p-2 flex flex-row rounded-full">
            <div className="flex justify-center items-center text-center min-w-60">
              <img
                className="h-64 w-64 rounded-lg mx-auto object-cover"
                src="self.jpg"
                id="profile-pic"
                alt="Alice Homolková"
                width={248}
                height={248}
              />
            </div>
            <div className="flex flex-col justify-between space-y-4 text-left text-pink-100 ml-4">
              <p className='bg-pink-100 rounded-lg text-gray-600 p-3'>
                Začalo to pletením copánků v dětství. Pokračovalo to natáčením kudrlinek v pubertě. A skončilo to rozhodnutím žít svůj sen v dospělosti.
                Jsem vyučená kadeřnice působící v Třebíči, v Kadeřnictví EVA, pracuji také jako kadeřnice do domu.
              </p>
              <p className='flex justify-center items-center bg-pink-100 rounded-lg text-gray-600 p-3'>
                Nabízím klasické kadeřnické služby s kreativním, ale především empatickým a pečlivým přístupem. 
                Na mé stránce můžete najít fotografie mých prací, ceník mých služeb a kontakt k objednání předem.
              </p>
            </div>
            </div>
            <div className='flex justify-center items-center'>
            <div className="m-4">
              <h3 className="text-xl font-semibold text-pink-600 mb-4">Rychlé informace:</h3>
              <div className="flex flex-wrap justify-center gap-2">
                {[
                  { icon: <Home className="w-4 h-4" />, text: 'Třebíč, kraj Vysočina' },
                  { icon: <Image className="w-4 h-4" />, text: '10+ let praxe' },
                  { icon: <GraduationCap className="w-4 h-4" />, text: 'Obchodní akademie a Hotelová škola Třebíč' },
                ].map((item, index) => (  
                  <div key={index} className="bg-pink-100 text-gray-600 rounded-full px-4 py-2 flex items-center space-x-2">
                    {item.icon}
                    <span className='text-gray-600'>{item.text}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div className="w-full bg-gradient-to-r from-pink-500 to-pink-300 h-20 flex items-center justify-center overflow-hidden relative">
      <div className="absolute inset-0 opacity-20">
        <svg className="w-full h-full" viewBox="0 0 100 100" preserveAspectRatio="none">
          <path d="M0,0 L100,0 L100,100 L0,100 Z" fill="none" stroke="white" strokeWidth="4" strokeDasharray="10,10" />
        </svg>
      </div>
      <motion.p
        key={currentSloganIndex}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.5 }}
        className="font-bold text-white text-3xl md:text-4xl text-center px-4 drop-shadow-lg"
      >
        {slogans[currentSloganIndex]}
      </motion.p>
    </div>
    <div id="galerie" className="min-h-screen bg-gradient-to-b from-pink-300 to-pink-100">
      <main className="max-w-6xl mx-auto px-4 py-12" ref={galleryRef}>
        <h1 className="text-4xl font-bold text-center text-pink-500 mb-8">Galerie</h1>
        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {visibleImages.map((image, index) => (
            <motion.div 
              key={index} 
              className="aspect-square relative overflow-hidden rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <img
                src={image}
                alt={`Galerie obrázek ${index + 1}`}
                className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
              />
            </motion.div>
          ))}
        </motion.div>
        <div className="text-center mt-10">
          <button
            onClick={handleToggleShowAll}
            className="bg-pink-500 hover:bg-pink-600 text-white font-semibold py-3 px-6 rounded-full transition-colors duration-300 shadow-md hover:shadow-lg"
          >
            {showAll ? 'Zobrazit méně' : 'Zobrazit více'}
          </button>
        </div>
      </main>
    </div>
    <div id="cenik" className="min-h-screen bg-gradient-to-b from-pink-400 to-pink-200 py-12 flex justify-center items-center">
      <div className=" mx-auto px-4 text-center">
        <Card className="bg-white p-4 shadow-xl text-center flex text-center justify-center items-center">
          <CardHeader className='text-center flex justify-center'>
            <span className="text-3xl text-center font-bold text-center text-pink-600">Ceník služeb</span>
          </CardHeader>
          <CardBody>
            <Table>
              <TableHeader>
                  <TableColumn className="w-2/3 bg-pink-100 text-pink-600">Služba</TableColumn>
                  <TableColumn className="w-1/3 bg-pink-100 text-pink-600 text-right">Cena &#40;Kč&#41;</TableColumn>
              </TableHeader>
              <TableBody>
                {services.map((service, index) => (
                  <TableRow key={index} className={index % 2 === 0 ? "bg-white" : "bg-pink-50"}>
                    <TableCell className="font-medium text-gray-600">{service.name}</TableCell>
                    <TableCell className="text-right text-gray-600">{service.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <p className="mt-4 text-sm text-gray-600">* dle spotřeby materiálu</p>
          </CardBody>
        </Card>
      </div>
    </div>
<div id="kontakt" className="min-h-screen bg-gradient-to-b from-pink-200 to-white py-12">
      <div className="max-w-6xl mx-auto px-4">
        <motion.h1 
          className="text-4xl font-bold text-center text-pink-500 mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Kontakt
        </motion.h1>
        <div className="grid md:grid-cols-2 gap-8 items-start">
          <motion.div 
            className={`aspect-square relative overflow-hidden rounded-lg shadow-lg transition-all duration-300 ease-in-out ${isMapExpanded ? 'md:col-span-2' : ''}`}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2606.374263284188!2d15.883560476861973!3d49.21242887572612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470d42360ce1f9b9%3A0x65a9746b873acd9d!2zQnLDoWZvdmEgdMWZLiA1MjUvNDEsIDY3NCAwMSBUxZllYsOtxI0gMQ!5e0!3m2!1scs!2scz!4v1725563535889!5m2!1scs!2scz"
              className="w-full h-full border-0"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
            <button
              onClick={() => setIsMapExpanded(!isMapExpanded)}
              className="absolute bottom-4 right-4 bg-pink-500 text-white px-4 py-2 rounded-full shadow-md hover:bg-pink-600 transition-colors duration-300"
            >
              {isMapExpanded ? 'Zmenšit mapu' : 'Zvětšit mapu'}
            </button>
          </motion.div>
          <div className="grid gap-4">
            {contactInfo.map((item, index) => (
              <motion.div 
                key={index}
                className="bg-white overflow-hidden shadow-md hover:shadow-lg rounded-lg transition-all duration-300 ease-in-out transform hover:-translate-y-1"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="p-6 flex items-start space-x-4">
                  <item.icon className="h-6 w-6 text-pink-400 flex-shrink-0 mt-1" />
                  <div className="text-left">
                    <h3 className="font-semibold text-pink-500 text-lg mb-2">{item.title}</h3>
                    {Array.isArray(item.content) ? (
                      item.content.map((line, i) => (
                        <p key={i} className="text-gray-600">{line}</p>
                      ))
                    ) : (
                      <p className="text-gray-600">{item.content}</p>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
        <motion.div 
          className="mt-12 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <h2 className="text-2xl font-semibold text-pink-500 mb-4">Sledujte mě</h2>
          <div className="flex justify-center space-x-4">
            <a href="https://www.facebook.com/Sluzby.Sakura33" className="text-pink-500 hover:text-pink-600 transition-colors duration-300">
              <Facebook className="h-8 w-8" />
            </a>
          </div>
        </motion.div>
      </div>
    </div>
    <footer className="w-full py-6 bg-gradient-to-r from-gray-50 to-white border-t border-gray-200">
      <div className="container mx-auto px-4 flex flex-col sm:flex-row items-center justify-between">
        <div className="flex items-center space-x-3 mb-4 sm:mb-0">
          <div className="relative w-10 h-10 bg-black rounded-full overflow-hidden">
            <img
              src="rp-logo.jpg"
              alt="Radim Pokorný Logo"
              layout="fill"
              objectFit="cover"
            />
          </div>
          <div className="flex flex-col">
            <span className="text-sm font-medium text-gray-900">
              vyvinutý od
            </span>
            <span className="text-lg font-semibold text-gray-800">
              Radim Pokorný
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <span className="text-sm text-gray-500">Designer & Developer</span>
          <a 
            href="https://radim-pokorny.cz/" 
            className="group relative px-4 py-2 text-sm font-medium text-gray-700 transition-colors duration-300 ease-in-out hover:text-gray-900"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="relative z-10">Navštívit</span>
            <span className="absolute inset-0 bg-gray-100 rounded transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out origin-left"></span>
          </a>
        </div>
      </div>
    </footer>
    <ScrollTop className="border-round-md bg-pink-50 p-3 rounded-full shadow-md" />

    </div>
  );
}

export default App;
